<template>
    <div class="animated fadeIn">
        <div class="wrapper">
            <div>
                <CRow>
                    <CCol lg="12">
                        <CButton
                            @click="$router.go(-1)"
                            size="sm"
                            color="warning" 
                        > 
                            <font-awesome-icon icon="arrow-left"/> Go back
                        </CButton>
                    </CCol>
                </CRow>
                <br>
                <CRow>
                    <CCol lg="5">
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="cog"/> Manage Department
                            <template v-if="department.status == 'Active'">
                                <CButton 
                                    size="sm"
                                    color="danger" 
                                    class="float-right" 
                                    @click="toggleEdit(), returnInformation()" 
                                    v-show="edit"
                                >
                                    <font-awesome-icon icon="window-close"/> Cancel
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('update-department')"
                                    size="sm"
                                    :disabled="!isValid"
                                    color="success" 
                                    class="float-right" 
                                    v-show="edit" 
                                    @click="updateDepartment()"
                                >
                                    <font-awesome-icon icon="save"/> Save
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('edit-department')"
                                    size="sm"
                                    color="info" 
                                    class="float-right" 
                                    @click="toggleEdit" v-show="!edit"
                                >
                                    <font-awesome-icon icon="edit"/> Edit
                                </CButton>
                            </template>
                            
                            <template v-if="department.status == 'Archived'">
                                <CButton 
                                    v-if="$store.getters['can']('restore-department')"
                                    size="sm"
                                    color="success" 
                                    class="float-right" 
                                    @click="restore()"
                                >
                                <font-awesome-icon icon="trash-restore"/> Restore
                                </CButton>
                            </template>
                            
                            </CCardHeader>
                            <CCardBody>
                                <CForm>
                                    <CRow>
                                        <CCol lg="12">
                                            <CInput 
                                                :disabled="disable == 1 ? true : false"
                                                :lazy="false"
                                                :value.sync="$v.department.name.$model"
                                                :isValid="checkIfValid('name')"
                                                label="Name" 
                                                type="text" 
                                                placeholder="Human Resources" 
                                                autocomplete="off"  
                                                v-model="department.name"
                                                invalidFeedback="Must be more than three(3) characters."
                                                v-tocapitalize
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CInput 
                                                readonly
                                                placeholder="John Doe" 
                                                v-model="department.head_name"
                                            >
                                                <template #append>
                                                    <CButton 
                                                        :disabled="disable == 1 ? true : false"
                                                        color="primary"  
                                                        @click="$refs.usersModal.users_modal = true, emitUserModal()"
                                                    >
                                                    <font-awesome-icon icon="search"/>
                                                    </CButton>
                                                </template>
                                            </CInput>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <CTextarea
                                                :disabled="disable == 1 ? true : false"
                                                label="Description"
                                                placeholder="Content..."
                                                autocomplete="off"  
                                                rows="3"
                                                v-model="department.description"
                                            />
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol lg="12">
                                            <label>Status</label>: <CBadge :color="getColorStatus(department.status)">{{department.status}}</CBadge>
                                        </CCol>
                                    </CRow>
                                    <CRow v-show="edit">
                                        <CCol lg="12">
                                            <CCard borderColor="danger" v-if="$store.getters['can']('archive-department')">
                                                <CCardHeader>
                                                    <font-awesome-icon 
                                                        icon="exclamation" 
                                                        :style="{ color: 'red' }"
                                                    /> 
                                                    Danger Zone
                                                </CCardHeader>
                                                <CCardBody>
                                                    <CRow>
                                                        <CCol lg="8">
                                                            <h6>Archive this Department ?</h6>
                                                        </CCol>
                                                        <CCol lg="12">
                                                            <label> Once archived, you cannot tag users to this department anymore. Please be certain.</label>
                                                        </CCol>
                                                    </CRow>
                                                    <br>
                                                    <CRow>
                                                        <CCol lg="3">
                                                            <CButton shape="pill" color="warning" @click="archive()" > 
                                                                <font-awesome-icon icon="minus-square" /> Archived 
                                                            </CButton>
                                                        </CCol>
                                                    </CRow>
                                                </CCardBody>
                                            </CCard>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol lg="7">
                        
                        <CCard>
                            <CCardHeader><font-awesome-icon icon="list-ul"/>  Groups</CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol lg="10" md="10" sm="10">
                                        <v-select 
                                            label="name"
                                            :disabled="disable == 1 ? true : false"
                                            v-model="selected_group"
                                            :options="groups_list"
                                            :class="!disable ? !group_required ? 'has-error' : 'has-success' : ''"
                                        />
                                    </CCol>
                                    <CCol lg="2" md="2" sm="2">
                                        <CButton    color="primary" 
                                                    @click="addGroup()"
                                                    :disabled="!group_required">
                                            <font-awesome-icon icon="plus"/>
                                        </CButton>
                                    </CCol>
                                    <br>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="department.groups"
                                            :fields="groups_list_fields"
                                            :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                            :items-per-page="5"
                                            :key="group_key"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                        >
                                            <template #icon="{item}">
                                                <td style="width: 10%;">
                                                    <center>
                                                        <CIcon :name="item.icon" size="xl" />
                                                    </center>
                                                </td>
                                            </template>
                                            <template #action="{item}">
                                                <td style="width: 10%;" >
                                                    <center>
                                                        <label v-if="item.general == '1'"> <i>Default</i> </label>
                                                        <CButton 
                                                            v-if="item.general != '1'"
                                                            color="danger" 
                                                            @click="removeGroup(item)" 
                                                            shape="pill" 
                                                            size="sm"
                                                        >
                                                            <font-awesome-icon icon="times"/>
                                                        </CButton>
                                                    </center>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
            <UsersModal ref="usersModal" @selectedUser="userSelected($event)"/>
        </div>
    </div>
</template>
<script>
import UsersModal from '../../modals/UsersModal'
import { required, minLength} from "vuelidate/lib/validators"
export default {
    name: 'DepartmentEdit',
    components: {
        UsersModal,
    },
    data () {
        return  {
            group_key: 0,
            disable: 1,
            edit: false,

            group_required: false,

            groups_list: [],
            groups_list_fields: [
                { key: 'icon',},
                { key: 'name' },
            ],
            selected_group: null,

            recently_added: [],
            recently_removed: [],

            department: {
                id: null,
                name: null,
                description: null,
                head_name: null,
                head_id: null,
                status: null,
            },

            department_backup: {
                id: null,
                name: null,
                description: null,
                head_name: null,
                head_id: null,
                status: null,
            }

        }
    },
    computed: {
        isValid () { return !this.$v.department.$invalid },
        isDirty () { return this.$v.department.$anyDirty },
    },
    created() {
        this.department.id = this.$route.params.id
        this.getDepartment();
        this.getGroups();
    },
    validations: { // FORM Validation (Vue)
        department: {
            name: {
                required, minLength: minLength(3)
            },
        }
    },
    methods: {
        userSelected: function (object) {
            this.department = Object.assign({}, this.department, { head_name: object.name, head_id: object.id })
        },
        emitUserModal: function () {
            this.$emit('show_users_modal');
        }, 
        validateForm:function () // JS Validation
        {
            if(!this.department.name) return false;
            return true;
        },
        validate: function() {
            this.$v.$touch()
        },
        checkIfValid: function(fieldName) {
            const field = this.$v.department[fieldName]
            if (!field.$dirty) {
                return null
            } 
            return !(field.$invalid || field.$model === '')
        },
        getDepartment: function() {
            this.$Progress.start()
            axios.get('/departments/' + this.department.id, {validateStatus: () => true}).then(response => {
                if( response.status == 200 ) {
                    this.department = {}
                    this.assignData(response.data)
                }
            })
        },
        getGroups: function() {
            axios.get('/groups/active-list', {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            id: response.data.data[i].id,
                            general: response.data.data[i].general,
                            icon: response.data.data[i].icon,
                            name: response.data.data[i].name
                        }
                        this.groups_list.push(option)
                    }
                }
            })
            this.$Progress.finish()
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.validate();
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            // ! DEEP CLONE //
                this.selected_group = null;
                this.recently_added = [];
                this.recently_removed = [];
                this.department = JSON.parse(JSON.stringify(this.department_backup))
            
        },
        updateDepartment: function () {
            if(JSON.stringify(this.department_backup) == JSON.stringify(this.department)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            if(this.validateForm()){
                let data = {
                    recently_added: this.recently_added,
                    recently_removed: this.recently_removed,
                    name: this.department.name,
                    description: this.department.description,
                    head_id: this.department.head_id,
                }
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update the department.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        this.$Progress.start()
                        return axios.post('/departments/update/' + this.department.id, data, {validateStatus: () => true})
                        .then(response => {
                            if( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${response.data.data.name} has been updated.`,
                                })
                                this.assignData(response.data)
                                this.toggleEdit();
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            return;
        },
        update: function () {
            this.$Progress.start()
            axios.post('/departments/update/' + this.department.id, this.department, {validateStatus: () => true})
            .then(response => {
                if( response.status == 200 ) {
                    this.$swal({
                        icon: "success",
                        title: "Success!",
                        text: `${response.data.data.name} has been updated.`,
                    })
                    this.assignData(response.data)
                    this.toggleEdit();
                    this.$Progress.finish()
                }
            })
        },
        archive: function() {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: `You are trying to archive the ${this.department.name}`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                }).then((result) => {
                if(result.value){
                    this.remove()
                }else{
                    this.$swal('Cancelled!','','error');
                }
            })

        },
        remove: function () {
            this.$Progress.start()
            axios.post('/departments/archive/' + this.department.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.department.name} successfully archived.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }).then( () => {
                        this.department.status = 'Archived'
                        this.toggleEdit()
                        this.$Progress.finish()
                    }) 
                }
            })
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/departments/restore/' + this.department.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.department.name} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }).then( () => {
                        this.department = Vue.util.extend({}, this.department_backup)
                        this.department.status = 'Active'
                        this.$Progress.finish()
                    }) 
                }
            })
        },
        assignData: function(response) {
            let group_ids = [];
            let new_groups = [];
            this.department = response.data;
            this.department.head_name = response.data.head ? `${response.data.head.first_name} ${response.data.head.last_name}${response.data.head.suffix ? ' '+response.data.head.suffix: ''}`: ''
            
            this.department.head_id = response.data.head ? response.data.head.id : ""
            this.department.status = response.data.deleted_at ? 'Archived' : 'Active';
            for (let i = 0; i < this.department.groups.length; i++) {
                let row = {
                    id: this.department.groups[i].id,
                    general: this.department.groups[i].general,
                    icon: this.department.groups[i].icon,
                    name: this.department.groups[i].name,
                }
                new_groups.push(row)
                group_ids.push(this.department.groups[i].id)
            }

            this.department.group_ids = group_ids;
            this.department.groups = new_groups;

            // ! DEEP CLONE //
            this.department_backup = JSON.parse(JSON.stringify(this.department))
        },
        addGroup:function() {
            this.group_key++;
            if(this.department.group_ids.includes(this.selected_group.id)) {
                this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Group already exists in the lists.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            } else {
                let row = {
                    id: this.selected_group.id,
                    general: this.selected_group.general,
                    icon: this.selected_group.icon,
                    name: this.selected_group.name,
                }
                this.department.groups.push(row)
                this.department.group_ids.push(this.selected_group.id)
                this.recently_added.push(this.selected_group.id)
                this.selected_group = null;
            }
        }, 
        removeGroup: function (item) {
            this.group_key++;
            let old_list = false;
            let new_list = false;
            for(let i = 0; i < this.department.groups.length; i++) {
                if(this.department.groups[i].name == item.name) {
                    old_list = true;
                    this.department.groups.splice(i, 1)
                    break;
                }
            }
            this.department.group_ids = this.department.group_ids.filter(e => e !== item.id)

            for (let j = 0; j < this.recently_added.length; j++) {
                if(this.recently_added[j] == item.id) {
                    new_list = true;
                    this.recently_added.splice(j, 1);
                    break;
                }
            }

            if(old_list && !new_list) {
                this.recently_removed.push(item.id)
            }
            
        }
    },
    watch: {
        selected_group: function (value) {
            this.group_required = value ? true : false
        },
        disable: function (value) {
            if(value == 0) {
                this.groups_list_fields.push({ key: 'action', sorter: false})
            } else {
                this.groups_list_fields = this.groups_list_fields.filter(e => e.key !== 'action')
            }
        }
    }
}
</script>